<div class="modal-dialog">
	<div class="modal-content">
		<div class="modal-body">
			<div class="row text-center">
				{{{confirmMsg}}}
			</div>
		</div>
		<div class="modal-footer">
			<div class="row text-center">
				<button id="ok" name="ok" class="x-ok x-close-modal btn btn-primary"><span>{{{okButtonText}}}</span></button>
				<button id="cancel" name="cancel" class="x-cancel btn"><span>{{{JSTextCodes_CANCEL}}}</span></button>
			</div>
		</div>
	</div>
</div>