{{#if containsTranslatedContent}}
	<div class="col-md-12 items-details-translation-disclaimer">{{{JspPublicCodes_JSP_TRANSLATION_DISCLAIMER}}}</div>
{{/if}}

<ul class="col-md-12 t2-breadcrumbs nav nav-pills">
	{{#if showGraphsForMySales}} 
		<li role="presentation" class="sales-back"><a href="{{contextPath}}/mySales">{{{JspPublicCodes_JSP_MY_SALES_BACK_TO_MY_SALES}}}</a></li>
	{{else}}
		{{#if marketplace}}
			<li role="presentation"><a href="{{contextPath}}/marketplace?continent={{continentIds}}">&laquo; {{{JspPublicCodes_JSP_VIEW_MARKETPLACE_FOR}}} {{continentCode}}</a></li>
		{{else}}
			<li role="presentation" id="viewAuction"><a href="{{contextPath}}/auctionDetails/{{auctionId}}">&laquo; {{{JspPublicCodes_JSP_VIEW_AUCTION}}}</a></li>
		{{/if}}		
	{{/if}}

	<li role="presentation" class="itemPreviousLink"><a href="{{contextPath}}/prevNextItemDetails/{{itemId}}/previous">«&nbsp;{{{JspPublicCodes_JSP_PREVIOUS_ITEM}}}</a></li>
	<li role="presentation" class="itemNextLink"><a href="{{contextPath}}/prevNextItemDetails/{{itemId}}/next">{{{JspPublicCodes_JSP_NEXT_ITEM}}}&nbsp;»</a></li>
</ul>
