<div class="auction-lot-title-hp">
	<h4><b>{{{auctioneerLabel}}}</b></h4>
</div>

<div class="row">
	<div class="col-md-6">
		<div class="text-center m-b-sm">
			<h2><img src="{{#cloudify auctioneer.cloudyLogo logoDims}}{{/cloudify}}" align="center"
					alt="logo auctioneer: {{{auctioneer.name}}}" /></h2>
			<div>
				<h2><a href="http://{{auctioneer.website}}">{{auctioneer.name}}</a></h2>
			</div>
			<div>{{{telephoneLabel}}}:{{auctioneer.contactNumber}}</div>
			<div>{{{emailLabel}}}:<a href="mailto:{{auctioneer.email}}">{{auctioneer.email}}</a></div>
			<div>{{{websiteLabel}}}:<a href="http://{{auctioneer.website}}">{{auctioneer.website}}</a></div>
		</div>
	</div>
	<div class="col-md-6">
		<h2>{{{aboutLabel}}} {{{auctioneer.name}}}</h2>
		<div class="aadetails-desc">
			<div class="wrapper">
				{{{auctioneer.description}}}
			</div>
		</div>
	</div>
</div>

<div class="row">
	<div class="col-md-12 col-sm-12 col-xs-12">
		<div class="row">
			<div class="col-md-12">
				<h4>{{{featuredMessage}}}</h4>
			</div>
		</div>
		{{#upcomingFeaturedAuctions}}
			<div id="featured_{{@index}}" class="t2-auction-list col-md-12">
				<div class="col-md-12">
					<div class="row">
						<div class="col-md-9">
							<h5 class="font-bold m-t-0 auctioneer-center-text">
								{{#if webcastType}}<i class="fa fa-wifi font-bold"></i>{{/if}} {{#if timedType}}<i
									class="fa fa-clock-o font-bold"></i>{{/if}} <a id="auctionDetails{{auctionId}}"
									href="{{../contextPath}}/auctionDetails/{{auctionId}}">{{{auctioneerName}}} - {{{title}}} <span
										id="numberOfItemsForAuction{{auctionId}}">({{itemCount}} {{{message}}})</span></a>
							</h5>
						</div>
						<div class="col-md-3">
							<h6 class="pull-right text-right m-t-xs">{{{location}}}</h6>
						</div>
					</div>
					<div class="t2-images">

						{{#if ../showAuctionLogos}}
						{{#if auctionLogo}}
						<a href="{{../contextPath}}/auctionDetails/{{auctionId}}">
							<span><img class="img-item-list" onerror="myApp.utils.errors.ImgErrorHomeAuctioneer(this)"
									src="{{#cloudify auctionLogo ../logoDims}}{{/cloudify}}" alt="{{name}}" /></span>
						</a>
						{{/if}}
						{{/if}}

						{{#upcomingItems}}
						<a href="{{../../contextPath}}/auctionDetails/{{../auctionId}}">
							{{#if attachmentModel}}
							<span><img class="img-item-list" onerror="myApp.utils.errors.ImgErrorHomeList(this)"
									src="{{#cloudify attachmentModel ../../dims}}{{/cloudify}}" alt="{{title}}" /></span>
							{{else}}
							<span class="img-error-list-home">{{../../awaitingImageMessage}}</span>
							{{/if}}
						</a>
						{{/upcomingItems}}
					</div>
				</div>
			</div>
			<div class="row col-md-12 m-t-sm">
				<p class="t2-auctioneer-desc auctioneer-center-text">{{{description}}}</p>
				<div class="row">
					<div class="col-md-8 font-bold col-xs-12 auctioneer-center-text">{{endsOrStarts}}</div>
					<div id="registration_{{auctionId}}"
						class="col-md-4 col-sm-6 col-xs-12 pull-right text-right font-bold auctioneer-center-text"></div>
				</div>
			</div>
		{{/upcomingFeaturedAuctions}}
	</div>

	<div class="row m-b-sm">
		<div class="col-md-12">
			<h4>{{{unfeaturedMessage}}}</h4>
		</div>
	</div>

	{{#upcomingAuctions}}
		<div id="unfeatured_{{@index}}" class="t2-auction-list col-md-12">
			<div class="row col-md-12">
				<div class="row">
					<div class="col-md-9">
						<h5 class="font-bold m-t-0 auctioneer-center-text">
							{{#if webcastType}}<i class="fa fa-wifi font-bold"></i>{{/if}} {{#if timedType}}<i
								class="fa fa-clock-o font-bold"></i>{{/if}} <a id="auctionDetails{{auctionId}}"
								href="{{../contextPath}}/auctionDetails/{{auctionId}}">{{{auctioneerName}}} - {{{title}}} <span
									id="numberOfItemsForAuction{{auctionId}}">({{itemCount}} {{{message}}})</span></a>
						</h5>
					</div>
					<div class="col-md-3">
						<h6 class="pull-right text-right m-t-xs">{{{location}}}</h6>
					</div>
				</div>
				<div class="t2-images">
					{{#if ../showAuctionLogos}}
						{{#if auctionLogo}}
							<a href="{{../contextPath}}/auctionDetails/{{auctionId}}">
								<span><img class="img-item-list" onerror="myApp.utils.errors.ImgErrorHomeAuctioneer(this)"
										src="{{#cloudify auctionLogo ../logoDims}}{{/cloudify}}" alt="{{{name}}}" /></span>
							</a>
						{{/if}}
					{{/if}}

					{{#upcomingItems}}
						<a href="{{../../contextPath}}/auctionDetails/{{../auctionId}}">
							{{#if attachmentModel}}
								<span><img class="img-item-list" onerror="myApp.utils.errors.ImgErrorHomeList(this)"
									src="{{#cloudify attachmentModel ../../dims}}{{/cloudify}}" alt="{{title}}" /></span>
							{{else}}
								<span class="img-error-list-home">{{../../awaitingImageMessage}}</span>
							{{/if}}
						</a>
					{{/upcomingItems}}
				</div>
			</div>
			<div class="row col-md-12 m-t-sm">
				<p class="t2-auctioneer-desc auctioneer-center-text">{{{description}}}</p>
				<div class="row">
					<div class="col-md-8 col-xs-12 font-bold auctioneer-center-text">{{endsOrStarts}}</div>
					<div id="registration_{{auctionId}}"
						class="col-md-4 col-sm-6 col-xs-12 pull-right text-right font-bold auctioneer-center-text"></div>
				</div>
			</div>
		</div>
	{{/upcomingAuctions}}
</div>
