<div>
	<div class="tab-content">
		{{#if hasVideos}}
		<div role="tabpanel" class="tab-pane {{videosActive}}" id="videos">
			<div class="row">
				{{#videos}}

				<div class="col-md-6 videos" >
					<iframe class="youtube" style="width: 90%" height="250px"  title="{{{label}}}" src="{{../protocol}}://www.youtube.com/embed/{{link}}?rel=0&autoplay=0" frameborder="0" allowfullscreen></iframe>
				</div>
				{{/videos}}
			</div>
		</div>
		{{/if}}
	</div>
</div>
