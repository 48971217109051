<div>
	<ul class="nav nav-tabs" role="tablist">
		<li role="presentation" class="{{descriptionActive}}"><a id="tab-description" href="#description" class="x-tab-change" aria-controls="description" role="tab" data-toggle="description">{{{JspPublicCodes_JSP_DESCRIPTION}}}</a></li>

		{{#if hasImages}}
	    <li role="presentation" class="{{imagesActive}}"><a id="tab-images" href="#images" class="x-tab-change" aria-controls="images" role="tab" data-toggle="images">{{{JspPublicCodes_JSP_PHOTOS_LABEL}}}</a></li>
		{{/if}}
		
		{{#if hasVideos}}
		  <li role="presentation"><a id="tab-videos" href="#videos" class="x-tab-change" aria-controls="videos" role="tab" data-toggle="videos">{{{JspPublicCodes_JSP_VIDEOS_LABEL}}}</a></li>
		{{/if}}
		
		{{#if hasDocuments}}
		  <li role="presentation"><a id="tab-documents" href="#documents" class="x-tab-change" aria-controls="documents" role="tab" data-toggle="documents">{{{JspPublicCodes_JSP_ADDITIONAL_DOCUMENTS}}}</a></li>
		{{/if}}
		
		<li role="presentation" id="saleInfoTabLink" aria-controls="sale-info" role="tab" class="saleInfoTab pull-right"><a class="x-saleinfo-modal clickable">{{{JspPublicCodes_JSP_AUCTION_INFORMATION}}}</a></li>
	</ul>
</div>
