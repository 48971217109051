<div class="modal-dialog">
	<div class="modal-content">
		<div class="modal-header">
			<h2 class="modal-title">{{{title}}}</h2>
		</div>
		<div class="modal-body">
			<div class="row">
				<div class="form-horizontal col-md-12">
					<p>{{{description}}}</p>
					<div class="form-group has-error">
						<div class="col-md-12">
							<span class="control-label x-errors"></span>
						</div>
					</div>
					<div class="form-group x-name-group">
						<div class="col-md-12">
							<label class="text-left" for="alertName">{{{JspPublicCodes_JSP_ITEM_ALERTS_CREATE_ALERT_NAME}}} <span>*</span></label>
							<input type="text" id="alertName" name="alertName" class="x-alert-name form-control" maxlength="200" value="{{{name}}}" />
						</div>
					</div>
					<div class="form-group x-query-group">
						<div class="col-md-12">
							<label class="text-left" for="query">{{{JspPublicCodes_JSP_ITEM_ALERTS_CREATE_ALERT_SEARCH_CRIT}}} <span>*</span></label>
							<input type="text" id="query" name="query" class="x-query form-control" maxlength="500" value="{{{query}}}" />
						</div>
					</div>
					<div class="form-group x-frequency-group">
						<div class="col-md-12">
							<label class="control-label">{{{JspPublicCodes_JSP_ITEM_ALERTS_CREATE_ALERT_FREQUENCY}}} <span>*</span></label>
						</div>
						<div class="col-md-12">
							<input id="sunday" name="sunday" class="x-sunday" type="checkbox" {{#if runOnSunday}}checked="checked"{{/if}} />
							<label class="control-label" for="sunday">{{{JSTextCodes_SUNDAY}}}</label>
						</div>
						<div class="col-md-12">
							<input id="monday" name="monday" class="x-monday" type="checkbox" {{#if runOnMonday}}checked="checked"{{/if}} />
							<label class="control-label" for="monday">{{{JSTextCodes_MONDAY}}}</label>
						</div>
						<div class="col-md-12">
							<input id="tuesday" name="tuesday" class="x-tuesday" type="checkbox" {{#if runOnTuesday}}checked="checked"{{/if}} />
							<label class="control-label" for="tuesday">{{{JSTextCodes_TUESDAY}}}</label>
						</div>
						<div class="col-md-12">
							<input id="wednesday" name="wednesday" class="x-wednesday" type="checkbox" {{#if runOnWednesday}}checked="checked"{{/if}} />
							<label class="control-label" for="wednesday">{{{JSTextCodes_WEDNESDAY}}}</label>
						</div>
						<div class="col-md-12">
							<input id="thursday" name="thursday" class="x-thursday" type="checkbox" {{#if runOnThursday}}checked="checked"{{/if}} />
							<label class="control-label" for="thursday">{{{JSTextCodes_THURSDAY}}}</label>
						</div>
						<div class="col-md-12">
							<input id="friday" name="friday" class="x-friday" type="checkbox" {{#if runOnFriday}}checked="checked"{{/if}} />
							<label class="control-label" for="friday">{{{JSTextCodes_FRIDAY}}}</label>
						</div>
						<div class="col-md-12">
							<input id="saturday" name="saturday" class="x-saturday" type="checkbox" {{#if runOnSaturday}}checked="checked"{{/if}} />
							<label class="control-label" for="saturday">{{{JSTextCodes_SATURDAY}}}</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<div class="row text-center">
				<div class="form-group">
					<button id="saveButton" name="saveButton" type="submit" class="x-submit x-close-modal btn btn-primary"><span>{{{JSTextCodes_SAVE}}}</span></button>
					<button id="cancelButton" name="cancelButton" class="x-cancel btn"><span>{{{JSTextCodes_CANCEL}}}</span></button>
				</div>
			</div>
		</div>
	</div>
</div>
