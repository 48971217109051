<div class="container">
	<div class="inner-content-box">
		<div class="row">
			<div class="col-xs-12 col-md-8 col-md-offset-2">
				<h2>{{{title}}}</h2>
				<div>
					<h5>{{{explanation}}}</h5>
					<div class="row">
						<div class="errors-global col-xs-12 col-md-12"></div>
					</div>
					<div class="row">
						<div class="col-xs-12 col-md-12">
							<div id="creditCardDetailsRegion"></div>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12 col-md-12">
							<div id="billingAddressDetailsRegion"></div>
						</div>
					</div>
					<button id="submitButton" name="verify" type="submit" class="x-verify btn btn-primary"><span>{{{buttonSubmit}}}</span></button>
					<button id="skipButton" name="skip" type="submit" class="x-skip btn"><span>{{{buttonSkip}}}</span></button>
				</div>
			</div>
		</div>
	</div>
</div>