<div class="row">
	<div class="input-unit ic-billingAddressLine1 form-group col-md-6">
		<label class="control-label" for="addressLine1">{{{JspPublicCodes_JSP_CARD_VERIFICATION_ADDRESS_LINE}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
		<input type="text" id="billingAddressLine1" name="billingAddressLine1" class="form-control" maxlength="30"/>
		<span id="addressLine1Errors" class="fieldError errors-billingAddressLine1"></span>
	</div>
</div>
<div class="row">
	<div class="input-unit ic-billingAddressLine2 form-group col-md-6">
		<label class="control-label" for="addressLine2">{{{JspPublicCodes_JSP_CARD_VERIFICATION_ADDRESS_LINE2}}} </label>
		<input type="text" id="billingAddressLine2" name="billingAddressLine2" class="form-control" maxlength="30"/>
		<span id="billingAddressLine2Errors" class="fieldError errors-billingAddressLine2"></span>
	</div>
</div>
<div class="row">
	<div class="input-unit ic-billingAddressCity form-group col-md-6">
		<label class="control-label" for="city">{{{JspPublicCodes_JSP_CARD_VERIFICATION_CITY}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
		<input type="text" id="billingAddressCity" name="billingAddressCity" class="form-control" maxlength="30"/>
		<span id="billingAddressCityErrors" class="fieldError errors-billingAddressCity"></span>
	</div>
</div>
<div class="row">
	<div class="input-unit ic-billingAddressCountyOrState form-group col-md-6">
		<label class="control-label" for="countyOrState">{{{JspPublicCodes_JSP_CARD_VERIFICATION_STATE}}}</label>
		<input type="text" id="billingAddressCountyOrState" name="billingAddressCountyOrState" class="form-control" maxlength="30"/>
		<span id="billingAddressCountyOrStateErrors" class="fieldError errors-billingAddressCountyOrState"></span>
	</div>
</div>
<div class="row">
	<div class="input-unit ic-billingAddressPostcode form-group col-md-6">
		<label class="control-label" for="postcode">{{{JspPublicCodes_JSP_CARD_VERIFICATION_POSTAL_CODE}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
		<input type="text" id="billingAddressPostcode" name="billingAddressPostcode" class="form-control" maxlength="30"/>
		<span id="billingAddressPostcodeErrors" class="fieldError errors-billingAddressPostcode"></span>
	</div>
</div>
<div class="row">
	<div class="input-unit ic-billingAddressCountry form-group col-md-6">
		<label class="control-label" for="country">{{{JspPublicCodes_JSP_CARD_VERIFICATION_COUNTRY}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
		<select id="billingAddressCountry"  name="billingAddressCountry" class="form-control">
			<option value="">{{{JspPublicCodes_JSP_LABEL_SELECT}}}</option>
			{{#each countries}}
				<option value="{{id}}">{{name}}</option>
			{{/each}}
		</select>
		<span id="billingAddressCountryErrors" class="fieldError errors-billingAddressCountry"></span>
	</div>
</div>