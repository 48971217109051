<div class="container">
	<table class="table table-striped">
		<thead>
			<tr>
				<th>{{{JspPublicCodes_JSP_ITEM_ALERTS_ALERT}}}</th>
				<th>{{{JspPublicCodes_JSP_ITEM_ALERTS_STATUS}}}</th>
				<th>Frequency</th>
				<th>{{{JspPublicCodes_JSP_ITEM_ALERTS_ACTIONS}}}</th>
				<th>{{{JspPublicCodes_JSP_ITEM_ALERTS_RESULTS}}}</th>
			</tr>
		</thead>
		<tbody id="tbody-region">
		</tbody>
	</table>
</div>
