
<div class="container ui-item-details">
	<div class="row">
		<div id="breadcrumbRegion" class="col-xs-12"></div>
	</div>
	<div class="row">
		<div id="biddingRegion" class="col-md-4 col-xs-12 {{#unless isLoggedIn}} user-not-logged-in {{/unless}}"></div>
		<div class="col-md-8 col-xs-12 ui-item-summary">
			<div class="panel panel-default margin-top">
				<div class="panel-body">
					<h2 class="margin-top--none" id="item-details--title">{{{numberOrRef}}}{{{itemTitle}}}</h2>
					<p id="item-details--summary">{{{itemSummary}}}</p>
				</div>
				<div class="row tabs-row" style="margin-bottom: 20px;">
					<div id="tabsRegion" class="col-xs-12"></div>
				</div>
				<div class="panel-body info-row">
					<div id="tabsTargetRegion"></div>
					<div id="auctioneerStripRegion"></div>
					<div id="relatedItemRegion" class="margin-top margin-bottom--none"></div>
				</div>
			</div>
		</div>
	</div>
</div>
