
{{#if relatedItems}}
	<div class="related-items">
		<h3 class="margin-top--none">{{{JspPublicCodes_JSP_RELATED_ITEMS}}}</h3>
		<ol class="breadcrumb">
			<li><a href="{{contextPath}}/search?tree={{category.treeId}}">{{{category.treeName}}}</a></li>
			{{#if category.parentId}}
				<li><a href="{{contextPath}}/search?category={{category.parentId}}">{{{category.parentName}}}</a></li>
			{{/if}}
			<li class="active"><a href="{{contextPath}}/search?subCategory={{category.id}}">{{{category.name}}}</a></li>
		</ol>

		<div class="related-items-images row">
			{{#relatedItems}}
				<div class="col-xs-4 col-md-2">
					<a id="related-item-{{itemId}}" href="{{../contextPath}}/itemDetails/{{auctionId}}/{{itemId}}" class="thumbnail text-center{{#unless attachmentModel}} img-error-thumb{{/unless}}">
						{{#if attachmentModel}}
							<img class="img-responsive" alt="related lot {{title}}" height="68" src="{{#cloudify attachmentModel ../dims}}{{/cloudify}}" onerror="myApp.utils.errors.ImgErrorThumb(this)"  data-width="{{../dims.width}}" data-height="{{../dims.height}}" title="{{{tooltip}}}" />
						{{else}}
							<div class="img-error-container">
								<div>
									<i class="fa fa-file-image-o fa-2x" />
								</div>
							</div>
						{{/if}}
					</a>
				</div>
			{{/relatedItems}}
		</div>
	</div>
{{/if}}
