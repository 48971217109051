<div class="tab-content">
  {{#if hasImages}}
    <div role="tabpanel" class="{{imagesActive}}" id="images">
      <div class="row">
        {{#images}}
          <div class="col-xs-4">
            <a class="thumbnail text-center" rel="prettyPhoto[itemImagesGallery1]"
              title="{{{../title}}} Image {{counter}} of {{../numbImages}}"
              href="{{#cloudify this ../lbDims}}{{/cloudify}}">
              <img class="img-responsive" alt="{{{originalFilename}}}" src="{{#cloudify this ../dims}}{{/cloudify}}"
                onerror="myApp.utils.errors.ImgErrorThumb(this)" />
            </a>
          </div>
        {{/images}}
      </div>
    </div>
  {{/if}}
</div>