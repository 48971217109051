<div>
	<div class="tab-content">
		{{#if hasDocuments}}
			<div role="tabpanel" class="tab-pane {{docsActive}}" id="documents">
				<div class="row">
					{{#if hasItemDocuments}}
						<div class="col-md-12">
							<h4>{{{JspPublicCodes_JSP_ITEM_DOCUMENTS}}}</h4>
						</div>
						{{#documents}}
							<div class="col-md-3 col-xs-4 text-center">
								<a href="{{{documentSrc}}}" target="_blank" title="{{{originalFilename}}}"> <i class="fa fa-file"></i><br> {{{label}}}<br>
									{{{originalFilename}}}
								</a><br> <small class="comment">[{{{size}}} kB]</small>
							</div>
						{{/documents}}
					{{/if}}
				</div>
				<div class="row">
					{{#if hasAuctionDocuments}}
						<div class="col-md-12">
							<h4>{{{JspPublicCodes_JSP_AUCTION_DOCUMENTS}}}</h4>
						</div>
						{{#aDocuments}}
							<div class="col-md-3 col-xs-4 text-center">
								<a href="{{{attachmentUrl}}}" target="_blank" title="{{{originalFilename}}}"> <i class="fa fa-file"></i><br> {{{label}}}<br>
									{{{originalFilename}}}
								</a><br> <small class="comment">[{{{size}}} kB]</small>
							</div>
						{{/aDocuments}}
					{{/if}}
				</div>
			</div>
		{{/if}}
	</div>
</div>
