<div class="row">
	<div class="input-unit ic-nameOnCard col-md-6 form-group">
		<label class="control-label" for="nameOnCard">{{{JspPublicCodes_JSP_CARD_VERIFICATION_NAME_ON_CARD}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
		<input type="text" id="nameOnCard" name="nameOnCard" class="form-control" maxlength="30"/>
		<span id="nameOnCardErrors" class="fieldError errors-nameOnCard"></span>
	</div>
</div>
<div class="row">
	<div class="input-unit ic-cardNumber col-md-6 form-group">
		<label class="control-label" for="cardNumber">{{{JspPublicCodes_JSP_CARD_VERIFICATION_CARD_NUMBER}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
		<input type="text" id="cardNumber" name="cardNumber" class="form-control" maxlength="30"/>
		<span id="cardNumberErrors" class="fieldError errors-cardNumber"></span>
	</div>
</div>
<div class="input-unit ic-cardExpirationMonth ic-cardExpirationYear">
	<div class="row">
		<div class="col-md-3">
			<label class="control-label" for="cardExpirationMonth">{{{JspPublicCodes_JSP_CARD_VERIFICATION_EXPIRATION_DATE}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12 form-group">
			<select id="cardExpirationMonth" name="cardExpirationMonth" class="form-control col-md-3">
				{{#each referenceExpirationMonths}}
					<option value="{{this}}">{{this}}</option>
				{{/each}}
			</select>
			<select id="cardExpirationYear" name="cardExpirationYear" class="form-control">
				{{#each referenceExpirationYears}}
					<option value="{{this}}">{{this}}</option>
				{{/each}}
			</select>
			<span id="cardExpirationMonthErrors" class="fieldError errors-cardExpirationYear errors-cardExpirationMonth"></span>
		</div>
	</div>
</div>
<div class="row">
	<div class="input-unit ic-securityCode col-md-3 form-group">
		<label class="control-label" for="securityCode">{{{JspPublicCodes_JSP_CARD_VERIFICATION_SECURITY_CODE}}} <span data-toggle="tooltip" title="{{{JspPublicCodes_JSP_CreateAccount_Required_Field}}}">*</span></label>
		<input type="text" id="securityCode" name="securityCode" class="form-control" size="4" maxlength="4"/>
		<span id="securityCodeErrors" class="fieldError errors-securityCode"></span>
	</div>
</div>
<div class="row">
	<div class="col-md-6 form-group">
		{{{JspPublicCodes_JSP_CARD_VERIFICATION_CVV_INFO}}}
	</div>
</div>